@import "./variables";

.footer {
    padding: 1% 2.5% 0.5% 2.5%;
    background: $dark-green;
    width: 95%;
    position: static;

    p, a, button {
        font-size: 1.25em;
    }

    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
        margin-bottom: 1em;
    }
    
    .row {
        display: grid;
        grid-template-columns: repeat(
            auto-fill,
            minmax(13em, 1fr)
        );
    
        @media (max-width: 1000px) {
            grid-template-columns: repeat(
                auto-fill,
                minmax(15em, 1fr)
            );
        }
    }
    
    .column {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 60px;
    }
    
    .heading {
        font-size: 1.75em;
        color: $blue;
        font-weight: bold;
        margin-bottom: 0.75em;
    }

    .padding {
        margin-bottom: 2em;
    }
    
    .link {
        color: $blue;
        margin-bottom: 0.75em;
        text-decoration: none;
    
        &:hover {
            color: black;
        }
    }

    .nav-links {
        display: grid;

        button {
            background: none!important;
            border: none;
            padding: 0!important;
            color: $blue;
            margin-bottom: 0.75em;
            text-decoration: none;
            text-align: left;
            cursor: pointer;

            &:hover {
                color: black;
            }
        }
    }

    .text {
        color: $blue;
        margin-top: 0em;
        margin-bottom: 0.75em;
    }
    
    .copyright {
        color: $grey;
        font-size: 0.75em;
    }    
}
