@import "./variables";

*{
    font-family: "Times New Roman", Times, serif;
}

body {
    background-color: $grey;
    color: black;
    margin: 0;

    display: flex;
    flex-flow: column wrap;
}

.page-content {
    width: 80%;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    margin-bottom: 4em;
}

.remove-bottom-margin {
    margin-bottom: 0 !important;
}

.centered-bullet-list {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    ol {
        display: inline-block;
        text-align: left;
    }
    ul {
        display: inline-block;
        text-align: left;
    }
}


.nav-buttons {
    button {
        color: $grey;
        background-color: $blue;
        border-color: $blue;

        &.active {
            background-color: $green;
            border-color: $green;
        }
    }
}

.quick-links {
    text-align: center;
    margin: 1em;
    a {
        color: $blue;
        margin: 0.5em;
        text-decoration: none;
    }
}
