@import "./variables";

.content {
    font-size: calc(10px + 2vmin);
    text-align: center;
    font-weight: bold;
    color: $white;
    -webkit-text-stroke-width: 0.06em;
    -webkit-text-stroke-color: black;
    width: 80%;
    margin: auto;

    h1 {
        font-size: 3.3em;
    }

    p {
        font-size: 1.9em;
    }
}