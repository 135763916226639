@import "./variables";

.header {
    background-color: $blue;
    padding-bottom: .25em;

    .logo {
        font-size: 2em;
        font-weight: bold;
        color: $white;
        -webkit-text-stroke-width: 0.03em;
        -webkit-text-stroke-color: black;
        text-decoration: none;

        img {
            vertical-align: middle;
            height: 2em;
            margin: 0.1em;
        }
    }

    .header-buttons {
        margin-left: 1em;
        text-align: center;
        
        button {
            font-size: 1em;
            margin: 0.5em;
            padding: 0.5em 1em;
            border-radius: 8px;
        }
    }
}
