@import "./variables";

.homepage-content {
    min-height: 100vh;
    font-size: calc(10px + 2vmin);
    background-color: $grey;
    background-image: url('../logo.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    width: 100%;
    margin: auto;

    .title {
        color: $dark-grey;
        display: flex;
        justify-content: center;
        text-align: center;
        font-weight: bold;
        -webkit-text-stroke-width: 0.04em;
        -webkit-text-stroke-color: black;
        font-size: 3.75em;
    }

    .about {
        display: flex;
        justify-content: center;
        text-align: center;
        background-color: hsla(215, 91%, 70%, 0.9);
        padding: 1em;
        margin: 1em;
    }

    .site-nav-buttons {
        text-align: center;
        
        button {
            font-size: 1.6em;
            margin: 0.8em;
            padding: 0.25em 0.5em;
            border-radius: 8px;
        }
    }
}
