@import "./variables";

.slideshowSlider {
    white-space: nowrap;
    transition: ease 800ms;
    width: 720px;
    height: 480px;
    @media (max-width: 1000px) {
      width: 360px;
      height: 240px;
    }
}

.slideshow {
    margin: 0 auto;
    overflow: hidden;
    max-width: 720px;
    @media (max-width: 1000px) {
      max-width: 360px;
    }
}

.slide {
    display: inline-block;
    width: 720px;
    height: 480px;
    @media (max-width: 1000px) {
      width: 360px;
      height: 240px;
    }
}

.slideImage {
    width: 720px;
    height: 480px;
    @media (max-width: 1000px) {
      width: 360px;
      height: 240px;
    }
}
